html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  padding-top: 64px; /* 为固定的导航栏留出空间 */
}

.home .slick-slider {
  height: calc(100vh - 64px);
}

.home .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.about, .services, .team, .contact {
  padding: 40px 0;
}

.about-paper {
  padding: 20px;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  margin-top: 40px;
}